.Selectable {
  font-family: Gilroy, Montserrat, sans-serif;
  width: 100%;
}
.Selectable .DayPicker-Month {
  width: 100%;
}

.Selectable .DayPicker-Caption > div {
  text-align: center;
}

.Selectable .DayPicker-NavButton--prev {
  left: 1.5em;
  right: auto;
  margin: 0;
  background-image:url("data:image/svg+xml,%0A%3Csvg width='10px' height='10px' viewBox='0 0 10 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='-' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Icon/Mini/Back/Blue' transform='translate(-10.000000, -6.000000)' stroke='%2305C3DE' stroke-width='2'%3E%3Cpolyline id='Path-3' transform='translate(15.000000, 15.000000) rotate(-270.000000) translate(-15.000000, -15.000000) ' points='7 11 15 19 23 11'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E"); 
}
.Selectable .DayPicker-NavButton--next {
  background-image: url("data:image/svg+xml,%0A%3Csvg width='10px' height='10px' viewBox='0 0 10 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='-' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg id='Icon/Mini/Next' transform='translate(-10.000000, -6.000000)' stroke='%2305C3DE' stroke-width='2'%3E%3Cpolyline id='Path-3' transform='translate(15.000000, 15.000000) rotate(-90.000000) translate(-15.000000, -15.000000) ' points='7 11 15 19 23 11'%3E%3C/polyline%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
  background-color: rgba(5, 195, 222, 0.2);
  color: #aaa;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: rgba(5, 195, 222, 1);
  color: #fff;
}

.Selectable .DayPicker-Day--start:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {
  font-weight: bold;
}

.Selectable .DayPicker-Day--end:not(.DayPicker-Day--outside):not(.DayPicker-Day--disabled) {

  font-weight: bold;
}

.DayPicker-Day.DayPicker-Day--weekend {
  position: relative;
}
.DayPicker-Day.DayPicker-Day--weekend:not(.DayPicker-Day--selected):after {
    content: '';
    background: rgba(0,0,0,0.05);
    display: block;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    position: absolute;
    border-radius: 5px;
}

.Selectable .DayPicker-Day {
  border-radius: 0 !important;
}
.Selectable .DayPicker-Day--start {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.Selectable .DayPicker-Day--end {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}